<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12">
            <h2>Configurar Loja</h2>
          </v-col>
          <v-col>
            <v-card>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="12">
                      <h3>
                        NOME E LINK DA LOJA
                      </h3>
                    </v-col>
                    <v-col cols="12" md="6">
                      <label for="name">Nome da Loja</label>
                      <input type="text" class="input-default" id="name" v-model="storeData.name"
                        placeholder="Nome da Loja">
                    </v-col>
                    <v-col cols="12" md="6">
                      <label for="cpf">CPF/CNPJ</label>
                      <input type="text" class="input-default" id="cpf" v-model="storeData.cpf" :readonly="cpfExists"
                        v-mask="['###.###.###-##', '##.###.###/####-##']" placeholder="000.000.000-00 / 00.000.000/0000-00">
                      <strong>
                        Certifique-se de que o CPF/CNPJ informado é o mesmo que está cadastrado em sua conta bancária como
                        PIX.
                      </strong>
                    </v-col>
                    <v-col cols="12" md="12">


                      <label for="link">Link da loja</label>
                      <v-btn icon small @click="linkDialog = true">
                        <v-icon color="red">mdi-help-circle</v-icon>
                      </v-btn>
                      <div class="input-group">
                        <span class="input-group-text">{{ config.url }}/</span>
                        <input type="text" class="input-default" id="link" v-model="storeData.slug"
                          @keyup="verifySlug()">
                      </div>
                      <small v-html="returnVerify"></small>
                    </v-col>

                    <v-col cols="12" md="12">
                      <h3>
                        REDES SOCIAIS
                      </h3>
                    </v-col>

                    <v-col cols="12" md="6">
                      <label for="facebook">Facebook</label>
                      <div class="input-group">
                        <span class="input-group-text">facebook.com/</span>
                        <input type="text" class="input-default" id="facebook" v-model="facebookUsername"
                          placeholder="Nome de usuário">
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <label for="instagram">Instagram</label>
                      <div class="input-group">
                        <span class="input-group-text">instagram.com/</span>
                        <input type="text" class="input-default" id="instagram" v-model="instagramUsername"
                          placeholder="Nome de usuário">
                      </div>
                    </v-col>

                    <v-col cols="12" md="6">
                      <label for="whatsapp">WhatsApp</label>
                      <div class="input-group">
                        <span class="input-group-text">wa.me/</span>
                        <input type="text" class="input-default" id="whatsapp" v-model="whatsappNumber"
                          placeholder="Número com DDD">
                      </div>
                    </v-col>

                    <v-col cols="12" md="12">
                      <h3>
                        <v-badge color="green" content="Novo" inline>
                          ENDEREÇO
                        </v-badge>
                      </h3>
                    </v-col>

                    <v-col cols="12" md="6">
                      <label for="cep">CEP</label>
                      <input type="text" class="input-default" v-mask="['#####-###']" @keyup="searchCep()" id="cep" v-model="storeData.zipCode" placeholder="00000-000">
                    </v-col>

                    <v-col cols="12" md="10">
                      <div class="form-item">
                        <label for="street">Rua</label>
                        <input type="text" class="input-default" ref="street" id="street" v-model="storeData.address">
                      </div>
                    </v-col>
                    <v-col cols="12" md="2">
                      <div class="form-item">
                        <label for="number">N°</label>
                        <input type="text" class="input-default" ref="number" id="number" placeholder="N°" v-model="storeData.number">
                      </div>
                    </v-col>

                    <v-col cols="12" md="5">
                      <div class="form-item">
                        <label for="city">Cidade</label>
                        <input type="text" class="input-default" ref="city" id="city" v-model="storeData.city">
                      </div>
                    </v-col>
                    <v-col cols="12" md="2">
                      <div class="form-item">
                        <label for="state">UF</label>
                        <input type="text" class="input-default" ref="state" id="state" v-model="storeData.state">
                      </div>
                    </v-col>

                    <v-col cols="12" md="12">
                      <h3>LOGO</h3>
                    </v-col>
                    <v-col cols="12">
                      <v-container class="card-logo" @click="$refs.logo.click()">
                        <v-row>
                          <v-col cols="6" md="4">
                            <img :src="storeData.logo" style="width: 70%;display: block;margin: 0 auto;margin-top: 22px"
                              alt="">
                          </v-col>
                          <v-col cols="12" md="6">
                            <input type="file" class="input-default" ref="logo" id="logo" @change="getFile"
                              placeholder="Logo" style="opacity: 0;position: absolute;left: -100rem;">
                            <p>Clique aqui para carregar sua logo.<br>
                              Dimensões: 500x125<br>
                              Formato: PNG<br>
                              Tamanho Máx: 2MB</p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>

                    <v-col cols="12" md="12">
                      <h3>BANNERS SLIDE</h3>
                    </v-col>

                    <v-col cols="4">
                      <v-container class="card-logo">
                        <v-row>
                          <v-col cols="12" md="12" @click="$refs.topCase.click()">
                            <img :src="storeData.topCase"
                              style="width: 70%;display: block;margin: 0 auto;margin-top: 10px" alt="">
                          </v-col>
                          <v-col cols="10" md="10">
                            <input type="file" class="input-default" ref="topCase" id="logo" @change="getFile2"
                              placeholder="Logo" style="opacity: 0;position: absolute;left: -100rem;">
                            <p>Clique aqui para carregar seu banner.<br>
                              Dimensões: 1400x620<br>
                              Formato: PNG<br>
                              Tamanho Máx: 2MB</p>
                          </v-col>
                          <v-col cols="12" md="2" class="d-flex justify-end align-end">
                            <v-btn icon @click.stop="confirmDelete('topCase')">
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>

                    <v-col cols="4">
                      <v-container class="card-logo">
                        <v-row>
                          <v-col cols="12" md="12" @click="$refs.topCase2.click()">
                            <img :src="storeData.topCase2"
                              style="width: 70%;display: block;margin: 0 auto;margin-top: 10px" alt="">
                          </v-col>
                          <v-col cols="10" md="10">
                            <input type="file" class="input-default" ref="topCase2" id="logo" @change="getFile3"
                              placeholder="Logo" style="opacity: 0;position: absolute;left: -100rem;">
                            <p>Clique aqui para carregar seu banner 2.<br>
                              Dimensões: 1400x620<br>
                              Formato: PNG<br>
                              Tamanho Máx: 2MB</p>
                          </v-col>
                          <v-col cols="12" md="2" class="d-flex justify-end align-end">
                            <v-btn icon @click.stop="confirmDelete('topCase2')">
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>

                    <v-col cols="4">
                      <v-container class="card-logo">
                        <v-row>
                          <v-col cols="12" md="12" @click="$refs.topCase3.click()">
                            <img :src="storeData.topCase3"
                              style="width: 70%;display: block;margin: 0 auto;margin-top: 10px" alt="">
                          </v-col>
                          <v-col cols="10" md="10">
                            <input type="file" class="input-default" ref="topCase3" id="logo" @change="getFile4"
                              placeholder="Logo" style="opacity: 0;position: absolute;left: -100rem;">
                            <p>Clique aqui para carregar seu banner 3.<br>
                              Dimensões: 1400x620<br>
                              Formato: PNG<br>
                              Tamanho Máx: 2MB</p>
                          </v-col>
                          <v-col cols="12" md="2" class="d-flex justify-end align-end">
                            <v-btn icon @click.stop="confirmDelete('topCase3')">
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>


                    <v-col cols="12" md="12">
                      <h3>BANNERS CATEGORIAS</h3>
                    </v-col>

                    <v-col cols="4">
                      <v-container class="card-logo">
                        <v-row>
                          <v-col cols="12" md="12" @click="$refs.banner1.click()">
                            <img :src="storeData.banner1"
                              style="width: 70%;display: block;margin: 0 auto;margin-top: 10px" alt="">
                          </v-col>
                          <v-col cols="12" md="12">
                            <input type="file" class="input-default" ref="banner1" id="logo" @change="getBanner1" placeholder="Logo" style="opacity: 0;position: absolute;left: -100rem;">
                            <p>
                              <strong>Lançamentos</strong><br>
                              Clique aqui para carregar seu banner.<br>
                              Dimensões: 1024x1024<br>
                              Formato: PNG<br>
                              Tamanho Máx: 2MB</p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                    
                    <v-col cols="4">
                      <v-container class="card-logo">
                        <v-row>
                          <v-col cols="12" md="12" @click="$refs.banner2.click()">
                            <img :src="storeData.banner2"
                              style="width: 70%;display: block;margin: 0 auto;margin-top: 10px" alt="">
                          </v-col>
                          <v-col cols="12" md="12">
                            <input type="file" class="input-default" ref="banner2" id="logo" @change="getBanner2" placeholder="Logo" style="opacity: 0;position: absolute;left: -100rem;">
                            <p>
                              <strong>Mais Vendidos</strong><br>
                              Clique aqui para carregar seu banner.<br>
                              Dimensões: 1024x1024<br>
                              Formato: PNG<br>
                              Tamanho Máx: 2MB</p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>

                    <v-col cols="4">
                      <v-container class="card-logo">
                        <v-row>
                          <v-col cols="12" md="12" @click="$refs.banner3.click()">
                            <img :src="storeData.banner3"
                              style="width: 70%;display: block;margin: 0 auto;margin-top: 10px" alt="">
                          </v-col>
                          <v-col cols="12" md="12">
                            <input type="file" class="input-default" ref="banner3" id="logo" @change="getBanner3" placeholder="Logo" style="opacity: 0;position: absolute;left: -100rem;">
                            <p>
                              <strong>Catálogo Completo</strong><br>
                              Clique aqui para carregar seu banner.<br>
                              Dimensões: 1024x1024<br>
                              Formato: PNG<br>
                              Tamanho Máx: 2MB</p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>

                    <v-col cols="12" md="12">
                      <h3>BANNER FIXO FINAL</h3>
                    </v-col>

                    <v-col cols="12">
                      <v-container class="card-logo">
                        <v-row>
                          <v-col cols="12" md="12" @click="$refs.bannerFixed.click()">
                            <img :src="storeData.bannerFixed"
                              style="width: 70%;display: block;margin: 0 auto;margin-top: 10px" alt="">
                          </v-col>
                          <v-col cols="12" md="12">
                            <input type="file" class="input-default" ref="bannerFixed" id="logo" @change="getBannerFixed" placeholder="Logo" style="opacity: 0;position: absolute;left: -100rem;">
                            <p>
                              <strong>Lançamentos</strong><br>
                              Clique aqui para carregar seu banner.<br>
                              Dimensões: 1024x1024<br>
                              Formato: PNG<br>
                              Tamanho Máx: 2MB</p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>


                    <v-col>
                      <button :class="{ 'button-default': true, 'disabled': !verified }" @click="save"
                        :disabled="!verified" v-if="!loading">Salvar</button>
                      <button :class="{ 'button-default': true, 'disabled': true }" v-if="loading">Aguarde...</button>

                      <!-- <p>{{ token }}</p> -->
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="saved" max-width="300">
      <v-card>
        <v-card-text>
          <img src="../../assets/success.gif" style="width: 100%;display: block;margin: 0 auto" alt="">
          <h2>
            Dados salvos com sucesso! para ver as alterações, clique no botão abaixo.
          </h2>
          <br>
          <v-btn color="pink" dark block :href="urlStore" target="_blank">Ver minha loja</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink darken-1" text @click="saved = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="linkDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">O que é o link?</v-card-title>
        <v-card-text>
          O link é utilizado para criar um endereço único para sua loja no site. Ele será parte da URL da sua loja.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink" text @click="linkDialog = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Confirmação de exclusão</v-card-title>
        <v-card-text>
          Tem certeza que deseja remover o banner personalizado?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            Cancelar
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteBanner">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mask } from 'vue-the-mask'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
  baseURL: config.baseURL
})

function validateCpf(cpf) {
  cpf = cpf.replace(/\D/g, '');
  if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
  var result = true;
  [9, 10].forEach(function (j) {
    var soma = 0, r;
    cpf.split(/(?=)/).splice(0, j).forEach(function (e, i) {
      soma += parseInt(e) * ((j + 2) - (i + 1));
    });
    r = soma % 11;
    r = (r < 2) ? 0 : 11 - r;
    if (r != cpf.substring(j, j + 1)) result = false;
  });
  return result;
}

function toSnakeCase(obj) {
  const result = {};
  for (const key in obj) {
    const snakeKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
    result[snakeKey] = obj[key];
  }
  return result;
}

function testDomain(domain) {
  return domain.indexOf('.') > -1;
}

export default {
  name: 'StorePage',
  metaInfo() {
    return {
      title: `Configurações da Loja - ${config.name} - Beeasy - ${this.userData.fullName}`
    }
  },
  directives: {
    mask
  },
  data() {
    return {
      config,
      term: '',
      returnVerify: '',
      saved: false,
      loading: false,
      cpfExists: false,
      verified: true,
      token: localStorage.token,
      urlStore: '',
      userData: {},
      storeData: {},
      products: [],
      productsFiltered: [],
      node_env: process.env.NODE_ENV,
      facebookUsername: '',
      instagramUsername: '',
      tiktokUsername: '',
      whatsappNumber: '',
      linkDialog: false,
      deleteDialog: false,
      bannerToDelete: ''
    }
  },
  async mounted() {
    await this.init()
    this.$store.commit('insertLog',{ action: 'Navegacao',  description: `[${this.userData.fullName}] acessou a página de configurações da loja.`})
  },
  methods: {
    async init() {
      this.userData = this.$store.getters.getUserData
      this.storeData = this.$store.getters.getStoreData
      if (this.storeData.cpf != null) {
        this.cpfExists = true
      }

      await this.prepareUrlStore()

      // Preenche os campos com os valores atuais
      this.facebookUsername = this.storeData.facebookLink ? this.storeData.facebookLink.replace('https://facebook.com/', '') : '';
      this.instagramUsername = this.storeData.instagramLink ? this.storeData.instagramLink.replace('https://instagram.com/', '') : '';
      this.tiktokUsername = this.storeData.tiktokLink ? this.storeData.tiktokLink.replace('https://tiktok.com/@', '') : '';
      this.whatsappNumber = this.storeData.whatsappLink ? this.storeData.whatsappLink.replace('https://wa.me/', '') : '';
    },
    async prepareUrlStore() {
      if (this.node_env === 'development') {
        // Ambiente de desenvolvimento
        this.urlStore = `https://teste.${config.url}/${this.$store.state.storeData.slug}`;
      } else {
        // Ambiente de produção
        if (testDomain(this.$store.state.storeData.slug)) {
          // Se o slug contém um ponto, usar como subdomínio
          this.urlStore = `https://${this.$store.state.storeData.slug}`;
        } else {
          // Caso contrário, usar como parte do caminho
          this.urlStore = `https://${config.url}/${this.$store.state.storeData.slug}`;
        }
      }
    },
    async verifySlug() {
      let app = this
      let store = JSON.parse(localStorage.getItem('store'))
      if (store.slug == this.storeData.slug) {
        app.verified = true
        return
      }

      this.storeData.slug = this.storeData.slug.replace(/[^a-zA-Z0-9-]/g, '').trim().toLowerCase()
      if (this.storeData.slug) {
        await api.get(`/stores/slug/${this.storeData.slug}`).then((response) => {
          if (response.data == false) {
            app.returnVerify = '<b style="color: green;">Link disponível</b>'
            app.verified = true
          } else {
            app.returnVerify = '<b style="color: red;">Link indisponível</b>'
            app.verified = false
          }
        })
      }
    },
    async getFile(e) {
      let file = e.target.files[0];
      let app = this
      let reader = new FileReader();
      reader.onloadend = function () {
        app.storeData.logo = reader.result
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    async getFile2(e) {
      let file = e.target.files[0];
      let app = this
      let reader = new FileReader();
      reader.onloadend = function () {
        app.storeData.topCase = reader.result
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    },

    async getFile3(e) {
      let file = e.target.files[0];
      let app = this
      let reader = new FileReader();
      reader.onloadend = function () {
        app.storeData.topCase2 = reader.result
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    },

    async getFile4(e) {
      let file = e.target.files[0];
      let app = this
      let reader = new FileReader();
      reader.onloadend = function () {
        app.storeData.topCase3 = reader.result
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    },


    async getBanner1(e) {
      let file = e.target.files[0];
      let app = this
      let reader = new FileReader();
      reader.onloadend = function () {
        app.storeData.banner1 = reader.result
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    },

    async getBanner2(e) {
      let file = e.target.files[0];
      let app = this
      let reader = new FileReader();
      reader.onloadend = function () {
        app.storeData.banner2 = reader.result
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    },

    async getBanner3(e) {
      let file = e.target.files[0];
      let app = this
      let reader = new FileReader();
      reader.onloadend = function () {
        app.storeData.banner3 = reader.result
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    },


    async getBannerFixed(e) {
      let file = e.target.files[0];
      let app = this
      let reader = new FileReader();
      reader.onloadend = function () {
        app.storeData.bannerFixed = reader.result
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    },


    confirmDelete(banner) {
      this.bannerToDelete = banner;
      this.deleteDialog = true;
    },
    async deleteBanner() {
      this.storeData[this.bannerToDelete] = 'delete';
      this.deleteDialog = false;
      await this.save();
    },
    async findCep(cep) {
      try {
        let response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async searchCep() {
      if (this.storeData.zipCode.length == 9) {
        let cep = this.storeData.zipCode.replace('-', '')
        let result = await this.findCep(cep)
        if (result.erro) {
          this.$toast.warning('CEP não encontrado. Insira o endereço manualmente.')
          this.cepResult = true
          this.$nextTick(() => this.$refs.street.focus())
          this.storeData.address = ''
          this.storeData.city = ''
          this.storeData.state = ''
          this.storeData.number = ''

        } else {
          this.cepResult = true
          this.$nextTick(() => this.$refs.number.focus())
          this.storeData.address = result.logradouro
          this.storeData.city = result.localidade
          this.storeData.state = result.uf
        }
        this.loading = false
      }
    },
    async save() {
      this.loading = true;
      try {
        if (this.storeData.name === '' || this.storeData.cpf === '' || this.storeData.cpf === null || this.storeData.slug === '' || this.storeData.logo === '' || this.storeData.topCase === '') {
          this.$toast.warning('Preencha todos os campos')
          this.loading = false;
          return
        }

        //if (validateCpf(this.storeData.cpf) == false) {
          //this.$toast.warning('CPF inválido')
          //return
        //}
        let store = JSON.parse(localStorage.getItem('store'))

        let whatsapp = this.whatsappNumber ? this.whatsappNumber.replace(/\D/g, '').length == 13 ? this.whatsappNumber.replace(/\D/g, '') : '55' + this.whatsappNumber.replace(/\D/g, '') : ''
        // if (whatsapp.length < 11 || whatsapp.length > 13) {
        //   this.$toast.warning('Número de WhatsApp inválido')
        //   this.loading = false;
        //   return
        // }
        const socialLinks = {
          facebookLink: this.facebookUsername ? `https://facebook.com/${this.facebookUsername}` : '',
          instagramLink: this.instagramUsername ? `https://instagram.com/${this.instagramUsername}` : '',
          tiktokLink: this.tiktokUsername ? `https://tiktok.com/@${this.tiktokUsername}` : '',
          whatsappLink: whatsapp ? `https://wa.me/` + whatsapp : ''
        }
        let updateData = toSnakeCase({
          name: this.storeData.name,
          cpf: this.storeData.cpf,
          slug: this.storeData.slug,
          logo: this.storeData.logo,
          top_case: this.storeData.topCase,
          top_case_2: this.storeData.topCase2,
          top_case_3: this.storeData.topCase3,
          address: this.storeData.address,
          number: this.storeData.number,
          zip_code: this.storeData.zipCode,
          state: this.storeData.state,
          city: this.storeData.city,
          banner_1: this.storeData.banner1,
          banner_2: this.storeData.banner2,
          banner_3: this.storeData.banner3,
          banner_fixed: this.storeData.bannerFixed,
          ...socialLinks
        });

        if (updateData.slug == store.slug) {
          delete updateData.slug
        }

        if (updateData.logo == store.logo) {
          delete updateData.logo
        }

        if (updateData.top_case == store.topCase) {
          delete updateData.top_case
        }

        if (updateData.top_case_2 == store.topCase2) {
          delete updateData.top_case_2
        }

        if (updateData.top_case_3 == store.topCase3) {
          delete updateData.top_case_3
        }

        if (updateData.banner_1 == store.banner1) {
          delete updateData.banner_1
        }

        if (updateData.banner_2 == store.banner2) {
          delete updateData.banner_2
        }
        
        if (updateData.banner_3 == store.banner3) {
          delete updateData.banner_3
        }

        if (updateData.banner_fixed == store.bannerFixed) {
          delete updateData.banner_fixed
        }

        this.$toast.info('Aguarde...')

        let response = await api.put(`/stores/${this.storeData.id}`, updateData, await this.$store.getters.getConfig)
        localStorage.setItem('store', JSON.stringify(response.data))
        this.$store.commit('insertLog',{ action: 'Atualizacao',  description: `[${this.userData.fullName}] atualizou as informações da loja, dados: ${JSON.stringify(updateData)}`})
        this.$store.commit('update_store', response.data)
        this.$toast.success('Informações salvas com sucesso!')
        this.saved = true;
        this.cpfExists = true;
        this.loading = false;
        await this.prepareUrlStore()

      } catch (error) {
        console.log(error)
        this.$toast.error('Erro ao salvar informações')
        this.loading = false;
      }
    }

  }
}
</script>

<style scoped>
* {
  font-family: "Outfit", sans-serif;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgba(64, 64, 73, .3);
}

.input-group {
  display: flex;
  align-items: center;
}

.input-group-text {
  background-color: #f0f0f0;
  border: 1px solid rgba(64, 64, 73, .3);
  border-radius: 5px 0 0 5px;
  padding: .5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #404049;
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid rgba(64, 64, 73, .3);
  border-radius: 0 5px 5px 0;
}

.input-default[readonly] {
  background-color: #f0f0f0;
}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

.disabled {
  background-color: #be95aea2;
  border: 1px solid #be95aea2;
  cursor: not-allowed;
}

@media screen and (max-width: 600px) {
  .container-page {
    width: 100% !important;
    padding-right: 12px;
  }
}

.card-logo {
  border: 1px solid #00AF7D;
  padding: 1rem;
  cursor: pointer;
  border-radius: 5px;
  float: left;
  transition: all .3s;
  position: relative;
}

.card-logo:hover {
  background-color: #f0f0f081;
}

.d-flex {
  display: flex;
}

.justify-end {
  justify-content: flex-end;
}

.align-end {
  align-items: flex-end;
}
</style>
