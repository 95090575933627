<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="12">
            <h2>Relatório de Pedidos</h2>
          </v-col>
          <v-col>
            <label for="">Data Inicial</label>
            <input type="date" v-model="start" class="input-default" placeholder="Inicio">
          </v-col>
          <v-col>
            <label for="">Data Final</label>
            <input type="date" v-model="end" class="input-default" placeholder="Fim">
          </v-col>
          <v-col>
            <label for="" style="opacity: 0">-</label>
            <button class="button-default" @click="getOrders">Filtrar</button>
          </v-col>
          
          <v-col cols=12>
            <v-data-table :headers="headers" :items="orders" :items-per-page="100000" class="elevation-1">

              <template v-slot:[`item.actions`]="{ item }">
                <a :href="item.link" class="button-default" target="blank">Ver</a>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12>
        <h2>Totalizadores</h2>
      </v-col>
      <v-col>
        <div class="card work">
          <div class="img-section">
            Comissões Franqueados
          </div>
          <div class="card-desc">
          <div class="card-header">
          <div class="card-title"></div>
          <div class="card-menu">
          <div class="dot2"></div>
          <div class="dot2"></div>
          <div class="dot2"></div>
          </div>
          </div>
          <div class="card-time">{{totalFranchiseeCommissions.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</div>
          </div>
        </div>
      </v-col>

      <v-col>
        <div class="card work">
          <div class="img-section">
            Custo Total
          </div>
          <div class="card-desc">
          <div class="card-header">
          <div class="card-title"></div>
          <div class="card-menu">
          <div class="dot2"></div>
          <div class="dot2"></div>
          <div class="dot2"></div>
          </div>
          </div>
          <div class="card-time">{{totalCostPrice.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</div>
          </div>
        </div>
      </v-col>

      <v-col>
        <div class="card work">
          <div class="img-section">
            Comissões Sistema
          </div>
          <div class="card-desc">
          <div class="card-header">
          <div class="card-title"></div>
          <div class="card-menu">
          <div class="dot2"></div>
          <div class="dot2"></div>
          <div class="dot2"></div>
          </div>
          </div>
          <div class="card-time">{{totalSystemComissions.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</div>
          </div>
        </div>
      </v-col>

      <v-col>
        <div class="card work">
          <div class="img-section">
            Comissões 2W
          </div>
          <div class="card-desc">
          <div class="card-header">
          <div class="card-title"></div>
          <div class="card-menu">
          <div class="dot2"></div>
          <div class="dot2"></div>
          <div class="dot2"></div>
          </div>
          </div>
          <div class="card-time">{{total2WComissions.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</div>
          </div>
        </div>
      </v-col>
    </v-row>
    <Loading v-if="loadingPage" />
  </v-container>
</template>

<script>
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
  baseURL: config.baseURL
})


export default {
  name: 'AdmOrdersPage',
  metaInfo() {
    return {
      title: `Relatório de Usuários - Óculos 2W - Beeasy - ${this.userData.fullName}`
    }
  },
  components: {
    Loading
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      headers: [
        {
          text: 'Código Pedido',
          align: 'center',
          sortable: false,
          value: 'Codigo_Pedido',
        },
        
        { text: 'Comprador', value: 'Comprador', align: 'center', },
        { text: 'Comprador Telefone', value: 'Comprador_Telefone', align: 'center', },
        { text: 'Comprador CPF', value: 'Comprador_CPF', align: 'center', },
        { text: 'Loja', value: 'Loja', align: 'center', },
        { text: 'Subtotal', value: 'Subtotal', align: 'center', },
        { text: 'Frete', value: 'Frete', align: 'center', },
        { text: 'Total', value: 'Total', align: 'center', },
        { text: 'Preço Custo', value: 'Preco_Custo', align: 'center', },
        { text: 'Taxa Transação', value: 'Taxa_Transacao', align: 'center', },
        { text: 'Taxa NF', value: 'Taxa_NF', align: 'center', },
        { text: 'Comissão Franqueado', value: 'Comissao_Franqueado', align: 'center', },
        { text: 'Comissão Sistema', value: 'Comissao_Sistema', align: 'center', },
        { text: 'Comissão 2W', value: 'Comissao_2W', align: 'center', },
        { text: 'Data Pedido', value: 'Data_Pedido', align: 'center', },
        { text: 'Comissão Repassada', value: 'Comissao_Repassada', align: 'center', },
        { text: 'Ações', value: 'actions', align: 'center',}
      ],
      orders: [],
      userData: {},
      storeData: {},
      start: '',
      end: '',
      loadingPage: false,
      totalFranchiseeCommissions: 0,
      totalSystemComissions: 0,
      total2WComissions: 0,
      totalCostPrice: 0
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      this.userData = this.$store.getters.getUserData
      this.storeData = this.$store.getters.getStoreData
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      this.start = firstDay.toISOString().split('T')[0]
      this.end = lastDay.toISOString().split('T')[0]
      await this.getOrders()
      
    },
    async getOrders() {
      try {
        this.orders = []
        this.loadingPage = true
        const { data } = await api.get(`/report/orders/${this.start}/${this.end}`, await this.$store.getters.getConfig)
        this.orders = data
        this.loadingPage = false

        this.totalFranchiseeCommissions = this.orders.reduce((acc, order) => {
          return acc + order.Comissao_Franqueado
        }, 0)

        this.totalSystemComissions = this.orders.reduce((acc, order) => {
          return acc + order.Comissao_Sistema
        }, 0)

        this.total2WComissions = this.orders.reduce((acc, order) => {
          return acc + order.Comissao_2W
        }, 0)

        this.totalCostPrice = this.orders.reduce((acc, order) => {
          return acc + order.Preco_Custo
        }, 0)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
* {
  font-family: "Outfit", sans-serif;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgba(64, 64, 73, .3);
}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

.column-pos {
  text-align: center;
  color: rgb(55 65 81);
  ;
  padding: .5rem;
}

.column-title {
  font-size: 1rem;
  font-weight: 400;
}

.column-subtitle {
  font-size: .8rem;
  font-weight: 400;
}
</style>
